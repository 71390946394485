import { Helmet } from 'react-helmet-async';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  updateStatusOnServer,
  saveNoteOnServer,
  updateNoteOnServer,
  deleteNoteOnServer,
  handleNoteAfterSave,
  handleNoteAfterUpdate,
} from 'src/utils/serverUpdates';
import { Card, Container, TablePagination, Grid, Box } from '@mui/material';
import { UserContext } from '../context/userContext';
import { UserListStatus, UserListToolbar } from '../sections/@dashboard/user';
import useGetLeadsBySearch from 'src/hooks/useGetLeadsBySearch';
import AutoNotification from 'src/components/lead-components/AutoNotification';
import useGetNewLeads from 'src/hooks/useGetNewLeads';
import DriverCardModal from 'src/components/lead-components/DriverCardModal';
import AddNewLeadModal from 'src/components/lead-components/AddNewLeadModal';
import NotePopover from 'src/components/lead-components/NotePopover';
import { AddNewLeadButton } from 'src/components/lead-components/AddNewLeadButton';
import useGetAllRecruiters from 'src/hooks/useGetAllRecruiters';
import { currentDateToTimezone, dayjs } from 'src/helpers/ConvertToTimezone';
import SelectDashboardColumns from 'src/components/lead-components/SelectDashboardColumns';
import DashboardTable from 'src/components/lead-components/DashboardTable';
import DeleteNotePopover from 'src/components/lead-components/DeleteNotePopover';
import { displayDashboardColumns, TABLE_HEAD } from 'src/utils/dashboardColumnSettings';
import RecruiterSelect from 'src/components/lead-components/RecruiterSelect';
import PositionSelect from 'src/components/lead-components/PositionSelect';

// function applyFilter(array, query) {
//   if (query) {
//     const lowerCaseQuery = query.toLowerCase();
//     return array.filter((element) => {
//       return (
//         element.lead_first_name.toLowerCase().includes(lowerCaseQuery) ||
//         element.lead_last_name.toLowerCase().includes(lowerCaseQuery) ||
//         // element.lead_full_name.toLowerCase().includes(lowerCaseQuery) ||
//         element.lead_email.toLowerCase().includes(lowerCaseQuery)
//       );
//     });
//   }
//   return array;
// }

export default function UserPage() {
  const { user, fetchingDataLoader, setFetchingDataLoader } = useContext(UserContext);

  const [allDrivers, setAllDrivers] = useState([]);
  const [selectedDriverNoteEmail, setSelectedDriverNoteEmail] = useState(null);
  const [currentDriver, setCurrentDriver] = useState({});
  const [currentSearchedStatus, setCurrentSearchedStatus] = useState('all');
  const [totalLeads, setTotalLeads] = useState(0);
  const [displayDataColumns, setDisplayDataColumns] = useState([]);
  const [dataColumnsBooleanState, setDataColumnsBooleanState] = useState({});

  const [editNote, setEditNote] = useState('');
  const [editNoteID, setEditNoteID] = useState('');
  const [isDeletingFlag, setIsDeletingFlag] = useState(false);
  const [isDeletingIndex, setIsDeletingIndex] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdatingIndex, setIsUpdatingIndex] = useState(null);
  const [deleteSelectedIdx, setDeleteSelectedIdx] = useState(null);

  const [open, setOpen] = useState(null);

  const [currentFilter, setCurrentFilter] = useState('all');
  const [currentSourceId, setCurrentSourceId] = useState(-1);
  const [page, setPage] = useState(0);

  // const [isUserSidebarOpen, setIsUserSidebarOpen] = useState(false);
  const [anchorDeleteNoteEl, setAnchorDeleteNoteEl] = useState(null);

  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(user.rowsPerPage ?? 50);

  const [allRecruiters, setAllRecruiters] = useState([]);
  const [filterByRecruiter, setFilterByRecruiter] = useState('');
  const [filterByPosition, setFilterByPosition] = useState('all');

  const [cardFieldsState, setCardFieldsState] = useState({
    meta_state: currentDriver?.lead_meta_state || currentDriver?.lead_state || '',
    meta_are_you_an_owner_op:
      currentDriver?.lead_meta_are_you_an_owner_op || currentDriver?.lead_are_you_an_owner_op || '',
    meta_owner_op: currentDriver?.lead_meta_owner_op || currentDriver?.lead_owner_op || '',
    meta_experience: currentDriver?.lead_meta_experience || currentDriver?.lead_experience || '',
    meta_violation: currentDriver?.lead_meta_violation || '',
    meta_accident: currentDriver?.lead_meta_accident || '',
    meta_lp_experience: currentDriver?.lead_meta_lp_experience || currentDriver?.lead_experience || '',
    meta_home_time: currentDriver?.lead_meta_home_time || '',
    meta_start_time: currentDriver?.lead_meta_start_time || '',
    meta_interested_in: currentDriver?.lead_meta_interested_in || currentDriver?.lead_interested_in || '',
    meta_truck_preference_1: currentDriver?.lead_meta_truck_preference_1 || '',
    meta_truck_preference_2: currentDriver?.lead_meta_truck_preference_2 || '',
    meta_trailer_type: currentDriver?.lead_meta_trailer_type || currentDriver?.lead_kind_of_trailer || '',
    meta_employment: currentDriver?.lead_meta_employment || '',
    meta_company_name: currentDriver?.lead_meta_company_name || '',
    meta_position: currentDriver?.lead_meta_position || currentDriver?.lead_interested_in || '',
    meta_why: currentDriver?.lead_meta_why || '',
    meta_miles: currentDriver?.lead_meta_miles || '',
    meta_pay: currentDriver?.lead_meta_pay || '',
    meta_drug_test: currentDriver?.lead_meta_drug_test || '',
    meta_note: currentDriver?.lead_meta_note || '',
  });

  const [newLeads, setNewLeads] = useState([]);
  const [newestLead, setNewestLead] = useState();
  const currentNewestLeadRef = useRef(newestLead);
  const filterByRecruiterRef = useRef(filterByRecruiter);
  const currentSearchedStatusRef = useRef(currentSearchedStatus);
  const pageRef = useRef(page);
  const rowsPerPageRef = useRef(rowsPerPage);
  const currentFilterByPositionRef = useRef(filterByPosition);
  const currentSourceIdRef = useRef(currentSourceId);
  const currentFilterNameRef = useRef(filterName);
  const [openLeadCard, setOpenLeadCard] = useState(false);
  const [openCreateLead, setOpenCreateLead] = useState(false);
  const isFirstFetch = useRef(true);

  const generalFetchLeads = async ({ changedRecruiter, filteredPosition }) => {
    setFetchingDataLoader(false);
    setAllDrivers([]);

    const recruiterId = user.recruiter_id ?? changedRecruiter ?? filterByRecruiter;
    const statusParam = currentSearchedStatus.toLowerCase();

    try {
      const data = await useGetLeadsBySearch({
        companyId: user.company_id,
        leadSource: currentSourceId,
        currentStatus: statusParam,
        searchTerm: filterName,
        page: 0,
        recruiterId: recruiterId,
        rowsPerPage,
        filteredPosition: filteredPosition ?? filterByPosition,
      });

      const finalLeads = Array.isArray(data.lead_list)
        ? data.lead_list.map((lead) => {
            return {
              ...lead,
              lead_status: data.status_list[lead.lead_id].status || 'not contacted',
              driverNotes: data.notes_list[lead.lead_id] || [],
              lead_status_modified: data.status_list[lead.lead_id].status_modified,
            };
          })
        : [];

      setTotalLeads(Number(data.total_leads));
      setAllDrivers(finalLeads);
      if (isFirstFetch.current) {
        setNewestLead(data.lead_list[0].lead_id);
        isFirstFetch.current = false;
      }
    } catch (error) {
      console.error('Something went wrong', error);
    } finally {
      setFetchingDataLoader(true);
    }
  };

  useEffect(() => {
    const dashboardColumns = JSON.parse(localStorage.getItem('dashboardColumns'));
    setFetchingDataLoader(false);
    if (dashboardColumns) {
      const tableHeadLabels = TABLE_HEAD.map((column) => column.label);

      tableHeadLabels.forEach((label) => {
        if (!dashboardColumns.hasOwnProperty(label)) {
          dashboardColumns[label] = false;
        }
      });

      Object.keys(dashboardColumns).forEach((label) => {
        if (!tableHeadLabels.includes(label)) {
          delete dashboardColumns[label];
        }
      });

      localStorage.setItem('dashboardColumns', JSON.stringify(dashboardColumns));

      const filteredTableHead = filterTableHead(dashboardColumns, TABLE_HEAD);
      setDisplayDataColumns(filteredTableHead);
      setDataColumnsBooleanState(dashboardColumns);
    } else {
      const filteredTableHead = filterTableHead(displayDashboardColumns, TABLE_HEAD);
      localStorage.setItem('dashboardColumns', JSON.stringify(displayDashboardColumns));
      setDisplayDataColumns(filteredTableHead);
      setDataColumnsBooleanState(displayDashboardColumns);
    }

    const fetchRecruiters = async () => {
      try {
        const recruiters = await useGetAllRecruiters(user.company_id);
        if (Array.isArray(recruiters)) {
          setAllRecruiters(recruiters);
          // setAllRecruiters(recruiters.filter((recruiter) => recruiter.recruiter_status !== 'disabled'));
        } else {
          setAllRecruiters([]);
        }
      } catch (error) {
        console.error('Failed to fetch recruiters', error);
      }
    };
    generalFetchLeads({ changedRecruiter: user.recruiter_id });
    fetchRecruiters();
  }, []);

  useEffect(() => {
    currentSearchedStatusRef.current = currentSearchedStatus;
    pageRef.current = page;
    currentSourceIdRef.current = currentSourceId;
    currentFilterNameRef.current = filterName;
    currentNewestLeadRef.current = newestLead;
    rowsPerPageRef.current = rowsPerPage;
    filterByRecruiterRef.current = filterByRecruiter;
    currentFilterByPositionRef.current = filterByPosition;
  }, [
    currentSearchedStatus,
    page,
    currentSourceId,
    filterName,
    newestLead,
    rowsPerPage,
    filterByRecruiter,
    filterByPosition,
  ]);

  const filterTableHead = (columns, tableHead) => {
    return tableHead.filter((item) => columns[item.label] === true);
  };

  const fetchAndProcessData = async ({ params }) => {
    try {
      const response = await useGetLeadsBySearch(params);

      const finalLeads = Array.isArray(response.lead_list)
        ? response.lead_list.map((lead) => {
            return {
              ...lead,
              lead_status: response.status_list[lead.lead_id].status || 'not contacted',
              driverNotes: response.notes_list[lead.lead_id] || [],
              lead_status_modified: response.status_list[lead.lead_id].status_modified,
            };
          })
        : [];

      setTotalLeads(Number(response.total_leads));
      setAllDrivers(finalLeads);
    } catch (err) {
      console.error('Error fetching data:', err);
    } finally {
      setFetchingDataLoader(true);
    }
  };

  useEffect(() => {
    const refetchData = async () => {
      const isRefetching = () => {
        return (
          pageRef.current === 0 &&
          (currentSearchedStatusRef.current === 'all' || currentSearchedStatusRef.current === 'new') &&
          currentFilterNameRef.current === ''
        );
      };

      if (isRefetching()) {
        const params = {
          companyId: user.company_id,
          leadSource: currentSourceIdRef.current,
          currentStatus: currentSearchedStatusRef.current,
          searchTerm: currentFilterNameRef.current,
          page: pageRef.current,
          recruiterId: user.recruiter_id ?? filterByRecruiterRef.current,
          rowsPerPage: rowsPerPageRef.current,
          filteredPosition: currentFilterByPositionRef.current,
        };
        fetchAndProcessData({ params });
      }
    };

    const showNotifications = () => {
      useGetNewLeads({
        latestLead: currentNewestLeadRef.current,
        companyId: user.company_id,
        recruiterId: user.recruiter_id ?? '',
      }).then((data) => {
        setNewestLead((prevNewestLead) => data[0]?.lead_id ?? prevNewestLead);
        setNewLeads(data.map((item) => item.lead_full_name.toString()));
      });
    };

    const interval = setInterval(() => {
      refetchData();
      showNotifications();
    }, 30000); // 30 seconds interval

    return () => clearInterval(interval);
  }, [user.company_id, user.recruiter_id]);

  const handleOpenNoteMenu = (event, lead_email) => {
    setOpen(event.currentTarget);
    setSelectedDriverNoteEmail(lead_email);
  };

  const handleOpenNoteUpdateMenu = (event, noteObject, idx) => {
    const { note_id, note_text } = noteObject;
    setOpen(event.currentTarget);
    setEditNote(note_text);
    setEditNoteID(note_id);
    setIsUpdatingIndex(idx);
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setSelectedDriverNoteEmail(null);
    setEditNote('');
  };

  const openDeletePopover = (event) => {
    setAnchorDeleteNoteEl(event.currentTarget);
  };

  const closeDeletePopover = () => {
    setAnchorDeleteNoteEl(null);
    setDeleteSelectedIdx(null);
  };

  // const toggleDrawer = (open) => (event) => {
  //   if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
  //     return;
  //   }
  //   setIsUserSidebarOpen(open);
  // };

  const handleOpenLeadCard = (currentId, leadsArray = allDrivers) => {
    const displayedDriver = leadsArray.find((driver) => driver.lead_id === currentId);
    setCurrentDriver(displayedDriver);
    setCardFieldsState({
      meta_state: displayedDriver.lead_meta_state || displayedDriver.lead_state || '',
      meta_are_you_an_owner_op:
        displayedDriver?.lead_meta_are_you_an_owner_op || displayedDriver?.lead_are_you_an_owner_op || '',
      meta_owner_op: displayedDriver?.lead_meta_owner_op || displayedDriver?.lead_owner_op || '',
      meta_experience: displayedDriver.lead_meta_experience || displayedDriver.lead_experience || '',
      meta_violation: displayedDriver.lead_meta_violation || '',
      meta_accident: displayedDriver.lead_meta_accident || '',
      meta_lp_experience: displayedDriver.lead_meta_lp_experience || '',
      meta_home_time: displayedDriver.lead_meta_home_time || '',
      meta_start_time: displayedDriver.lead_meta_start_time || '',
      meta_interested_in: displayedDriver?.lead_meta_interested_in || displayedDriver?.lead_interested_in || '',
      meta_truck_preference_1: displayedDriver.lead_meta_truck_preference_1 || '',
      meta_truck_preference_2: displayedDriver.lead_meta_truck_preference_2 || '',
      meta_trailer_type: displayedDriver.lead_meta_trailer_type || displayedDriver?.lead_are_you_an_owner_op || '',
      meta_employment: displayedDriver.lead_meta_employment || '',
      meta_company_name: displayedDriver.lead_meta_company_name || displayedDriver?.lead_company || '',
      meta_position: displayedDriver.lead_meta_position || '',
      meta_why: displayedDriver.lead_meta_why || '',
      meta_miles: displayedDriver.lead_meta_miles || '',
      meta_pay: displayedDriver.lead_meta_pay || '',
      meta_drug_test: displayedDriver.lead_meta_drug_test || '',
      meta_note: displayedDriver.lead_meta_note || '',
    });
    setOpenLeadCard(true);
  };

  const handleOpenCreateLead = () => {
    setOpenCreateLead(true);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
    setFetchingDataLoader(false);
    setAllDrivers([]);
    const statusParam = currentSearchedStatus.toLowerCase();
    useGetLeadsBySearch({
      companyId: user.company_id,
      leadSource: currentSourceId,
      currentStatus: statusParam,
      searchTerm: filterName,
      page: newPage,
      recruiterId: user.recruiter_id ?? filterByRecruiter,
      rowsPerPage,
      filteredPosition: filterByPosition,
    })
      .then((data) => {
        const finalLeads = Array.isArray(data.lead_list)
          ? data.lead_list.map((lead) => {
              return {
                ...lead,
                lead_status: data.status_list[lead.lead_id].status || 'not contacted',
                driverNotes: data.notes_list[lead.lead_id] || [],
                lead_status_modified: data.status_list[lead.lead_id].status_modified,
              };
            })
          : [];

        setTotalLeads(Number(data.total_leads));
        setAllDrivers(finalLeads);
      })
      .catch((error) => {
        console.error('Something went wrong', error);
      })
      .finally(() => {
        setFetchingDataLoader(true);
      });
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setPage(0);
    setRowsPerPage(newRowsPerPage);
    setFetchingDataLoader(false);

    if (user.rowsPerPage !== newRowsPerPage) {
      user.rowsPerPage = newRowsPerPage;
      localStorage.setItem('user', JSON.stringify(user));
    }

    const params = {
      companyId: user.company_id,
      leadSource: currentSourceId,
      currentStatus: currentSearchedStatus,
      searchTerm: filterName,
      page: 0,
      recruiterId: user.recruiter_id ?? filterByRecruiter,
      rowsPerPage: newRowsPerPage,
      driver_position: filterByPosition,
    };
    fetchAndProcessData({ params });
  };

  const handleFilterBySearch = (searchParam) => {
    setPage(0);
    setFilterName(searchParam);
  };

  // const filteredUsers = applyFilter(allDrivers, filterName);

  const isNotFound = allDrivers.length === 0 && !!filterName;

  const handleStatusChange = (checkLeadId, newStatus) => {
    const updatedDrivers = allDrivers.map((driver) => {
      if (driver.lead_id === checkLeadId) {
        const timestamp = [dayjs(currentDateToTimezone()).format('YYYY-MM-DD HH:mm:ss')];

        const timeLogEntry = {
          [timestamp]: {
            recruiter_id: user.recruiter_id ?? user.company_id,
            event_name: 'status',
            content: newStatus,
          },
        };
        return {
          ...driver,
          lead_status: newStatus,
          lead_status_modified: currentDateToTimezone(),
          lead_event_log: {
            ...driver.lead_event_log,
            ...timeLogEntry,
          },
        };
      }
      return driver;
    });

    const lead = updatedDrivers.find((lead) => lead.lead_id === checkLeadId);
    // console.log(lead);
    if (lead) {
      updateStatusOnServer(lead.lead_id, user.company_id, newStatus, user.recruiter_id).catch((error) => {
        console.error('An error occurred:', error);
      });
    }

    if (currentDriver && currentDriver.lead_id === checkLeadId) {
      setCurrentDriver({
        ...currentDriver,
        lead_status: newStatus,
        lead_status_modified: currentDateToTimezone(),
        lead_event_log: {
          ...currentDriver.lead_event_log,
          [dayjs().format('YYYY-MM-DD HH:mm:ss')]: {
            recruiter_id: user.recruiter_id ?? user.company_id,
            event_name: 'status_changed',
            content: `Status changed to: "${newStatus}"`,
          },
        },
      });
    }

    setAllDrivers(updatedDrivers);
  };

  // console.log(currentDriver, 'herereer');

  const handleSaveNote = async (newNoteText) => {
    const findDriver = allDrivers.find((driver) => driver.lead_email === selectedDriverNoteEmail);

    if (findDriver && newNoteText !== '' && newNoteText !== null) {
      const existingNotes = findDriver.driverNotes || [];
      const newNote = {
        note_id: '',
        note_text: newNoteText,
        note_modified_date: currentDateToTimezone(),
      };

      try {
        const data = await saveNoteOnServer(findDriver.lead_id, user.company_id, newNoteText, user.recruiter_id ?? '');
        newNote.note_id = data.toString();
        const timestamp = [dayjs(currentDateToTimezone()).format('YYYY-MM-DD HH:mm:ss')];

        const timeLogEntry = {
          [timestamp]: {
            recruiter_id: user.recruiter_id ?? user.company_id,
            event_name: 'note_created',
            content: newNoteText,
          },
        };

        const updatedDrivers = allDrivers.map((driver) => {
          if (driver.lead_email === selectedDriverNoteEmail) {
            return {
              ...driver,
              driverNotes: [newNote, ...existingNotes],
              lead_event_log: {
                ...driver.lead_event_log,
                ...timeLogEntry,
              },
            };
          }
          return driver;
        });
        setAllDrivers(updatedDrivers);

        const updatedCurrentDriver = updatedDrivers.find((driver) => driver.lead_email === selectedDriverNoteEmail);
        // console.log(updatedCurrentDriver, 'current drirrver');

        setCurrentDriver(updatedCurrentDriver);

        handleCloseMenu();

        try {
          await handleNoteAfterSave(findDriver.lead_id, user.company_id, newNoteText);
        } catch (error) {
          console.error('An error occurred in handleNoteAfterSave:', error);
        }
      } catch (error) {
        console.error('An error occurred:', error);
        handleCloseMenu();
      }
    }
  };

  //We use the states to alter the selected note data:
  const handleUpdateNote = async (updatedNoteText) => {
    setIsUpdating(true);

    const generateUpdateNoteLog = ({ updatedNoteText, previousNoteText }) => {
      const timestamp = [dayjs(currentDateToTimezone()).format('YYYY-MM-DD HH:mm:ss')];

      return {
        [timestamp]: {
          recruiter_id: user.recruiter_id ?? user.company_id,
          event_name: 'note_updated',
          content: `${previousNoteText} {[(*)]} ${updatedNoteText}`,
        },
      };
    };

    try {
      await updateNoteOnServer(
        currentDriver.lead_id,
        user.company_id,
        editNoteID,
        updatedNoteText,
        user.recruiter_id ?? ''
      );
      setIsUpdating(false);

      const updatedDrivers = allDrivers.map((driver) => {
        const previousNote = driver.driverNotes.find((note) => note.note_id === editNoteID);
        const previousNoteText = previousNote ? previousNote.note_text : '';

        if (driver.lead_email === currentDriver.lead_email) {
          return {
            ...driver,
            driverNotes: [
              { note_id: editNoteID, note_text: updatedNoteText, note_modified_date: currentDateToTimezone() },
              ...currentDriver.driverNotes.filter((note) => note.note_id !== editNoteID),
            ],
            lead_event_log: {
              ...driver.lead_event_log,
              ...generateUpdateNoteLog({ updatedNoteText, previousNoteText }),
            },
          };
        }
        return driver;
      });

      const currentDriverPreviousNote = currentDriver.driverNotes.find((note) => note.note_id === editNoteID);
      const currentDriverPreviousNoteText = currentDriverPreviousNote ? currentDriverPreviousNote.note_text : '';

      setCurrentDriver({
        ...currentDriver,
        driverNotes: [
          { note_id: editNoteID, note_text: updatedNoteText, note_modified_date: currentDateToTimezone() },
          ...currentDriver.driverNotes.filter((note) => note.note_id !== editNoteID),
        ],
        lead_event_log: {
          ...currentDriver.lead_event_log,
          ...generateUpdateNoteLog({ updatedNoteText, previousNoteText: currentDriverPreviousNoteText }),
        },
      });

      setAllDrivers(updatedDrivers);
      handleCloseMenu();

      try {
        await handleNoteAfterUpdate(currentDriver.lead_id, user.company_id, updatedNoteText);
      } catch (error) {
        console.error('An error occurred in handleNoteAfterUpdate:', error);
      }
    } catch (error) {
      console.error('An error occurred while updating the note:', error);
      setIsUpdating(false);
      handleCloseMenu();
    }
  };

  const handleDeleteNote = () => {
    setIsDeletingIndex(deleteSelectedIdx);
    setIsDeletingFlag(true);

    const noteToDelete = currentDriver.driverNotes[deleteSelectedIdx];

    const updatedNotes = currentDriver.driverNotes.filter((_, idx) => idx !== deleteSelectedIdx);

    deleteNoteOnServer(noteToDelete.note_id, currentDriver.lead_id, user.company_id, user.recruiter_id)
      .then(() => {
        const timestamp = [dayjs(currentDateToTimezone()).format('YYYY-MM-DD HH:mm:ss')];
        const timeLogEntry = {
          [timestamp]: {
            recruiter_id: user.recruiter_id ?? user.company_id,
            event_name: 'note_deleted',
            content: noteToDelete.note_text,
          },
        };

        const updatedDrivers = allDrivers.map((driver) => {
          if (driver.lead_email === currentDriver.lead_email) {
            return {
              ...driver,
              driverNotes: updatedNotes,
              lead_event_log: {
                ...driver.lead_event_log,
                ...timeLogEntry,
              },
            };
          }
          return driver;
        });
        setAllDrivers(updatedDrivers);

        setCurrentDriver({
          ...currentDriver,
          driverNotes: updatedNotes,
          lead_event_log: {
            ...currentDriver.lead_event_log,
            ...timeLogEntry,
          },
        });
      })
      .catch((error) => {
        console.error('An error occurred:', error);
      })
      .finally(() => {
        setIsDeletingFlag(false);
        setIsDeletingIndex(null);
      });
  };

  const onStatusToolbarClick = (event) => {
    setCurrentSearchedStatus(event.currentTarget.innerText.toLowerCase());
    currentSearchedStatusRef.current = event.currentTarget.innerText.toLowerCase();
    setPage(0);
  };

  return (
    <>
      <Helmet>
        <title> {`${user.company_name}`} | Leads </title>
      </Helmet>
      <Container maxWidth="100%" sx={{ height: 'calc(100vh - 72px)' }}>
        <Card>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={3}>
              <AddNewLeadButton handleOpenCreateLead={handleOpenCreateLead} />
            </Grid>
            {/* <Grid item xs={3} display="flex" justifyContent="center">
              <FilterSourceButtons
                user={user}
                setCurrentFilter={setCurrentFilter}
                currentFilter={currentFilter}
                setCurrentSourceId={setCurrentSourceId}
                setFetchingDataLoader={setFetchingDataLoader}
                setAllDrivers={setAllDrivers}
                setTotalLeads={setTotalLeads}
              />
            </Grid> */}
            <Grid item xs={2} display="flex" justifyContent="center">
              {!user.recruiter_id && allRecruiters.length > 1 && (
                <Box
                  sx={{
                    display: 'flex',
                    p: '10px',
                    maxWidth: '100%',
                  }}
                >
                  <RecruiterSelect
                    allRecruiters={allRecruiters}
                    selectedRecruiter={filterByRecruiter}
                    onChange={(changedRecruiter) => {
                      setFilterByRecruiter(changedRecruiter);
                    }}
                    filterBy
                    generalFetchLeads={generalFetchLeads}
                    width="244px"
                  />
                </Box>
              )}
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="center">
              <SelectDashboardColumns
                dataColumnsBooleanState={dataColumnsBooleanState}
                filterTableHead={filterTableHead}
                setDisplayDataColumns={setDisplayDataColumns}
                setDataColumnsBooleanState={setDataColumnsBooleanState}
              />
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="center">
              <Box
                sx={{
                  display: 'flex',
                  p: '10px',
                  maxWidth: '100%',
                }}
              >
                <PositionSelect
                  selectedPosition={filterByPosition}
                  onChange={(selectedPosition) => {
                    setFilterByPosition(selectedPosition);
                  }}
                  filterBy
                  generalFetchLeads={generalFetchLeads}
                  width="244px"
                />
              </Box>
            </Grid>
            <Grid item xs={3} display="flex" justifyContent="flex-end">
              <UserListToolbar
                user={user}
                currentFilter={currentFilter}
                currentSearchedStatus={currentSearchedStatus}
                currentSourceId={currentSourceId}
                setFetchingDataLoader={setFetchingDataLoader}
                setAllDrivers={setAllDrivers}
                filterName={filterName}
                onFilterName={handleFilterBySearch}
                rowsPerPage={rowsPerPage}
                setTotalLeads={setTotalLeads}
                filterByRecruiter={filterByRecruiter}
                filterByPosition={filterByPosition}
              />
            </Grid>
          </Grid>
          <UserListStatus
            user={user}
            onStatusToolbarClick={onStatusToolbarClick}
            currentSourceId={currentSourceId}
            currentFilter={currentFilter}
            setAllDrivers={setAllDrivers}
            setFetchingDataLoader={setFetchingDataLoader}
            rowsPerPage={rowsPerPage}
            setTotalLeads={setTotalLeads}
            filterByRecruiter={filterByRecruiter}
            filterByPosition={filterByPosition}
          />

          <DashboardTable
            allDrivers={allDrivers}
            displayDataColumns={displayDataColumns}
            fetchingDataLoader={fetchingDataLoader}
            handleOpenLeadCard={handleOpenLeadCard}
            handleStatusChange={handleStatusChange}
            handleOpenNoteMenu={handleOpenNoteMenu}
            isNotFound={isNotFound}
            currentSearchedStatus={currentSearchedStatus}
            companyLogo={user.company_full_logo}
            dataColumnsBooleanState={dataColumnsBooleanState}
            filterName={filterName}
          />

          {fetchingDataLoader && allDrivers.length !== 0 && !isNotFound && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={Number(totalLeads)}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>
      </Container>
      <NotePopover
        open={open}
        handleCloseMenu={handleCloseMenu}
        editNote={editNote}
        handleSaveNote={handleSaveNote}
        handleUpdateNote={handleUpdateNote}
      />
      {/* Sliding Sidebar */}
      {/* <FullLeadPreview
        isUserSidebarOpen={isUserSidebarOpen}
        toggleDrawer={toggleDrawer}
        currentDriver={currentDriver}
        handleOpenUpdateMenu={handleOpenNoteUpdateMenu}
        isUpdating={isUpdating}
        isUpdatingIndex={isUpdatingIndex}
        isDeletingIndex={isDeletingIndex}
        isDeletingFlag={isDeletingFlag}
        setDeleteSelectedIdx={setDeleteSelectedIdx}
        openDeletePopover={openDeletePopover}
        setCurrentDriver={setCurrentDriver}
        handleStatusChange={handleStatusChange}
      /> */}
      <DriverCardModal
        currentDriver={currentDriver}
        setCurrentDriver={setCurrentDriver}
        allRecruiters={allRecruiters}
        handleStatusChange={handleStatusChange}
        openLeadCard={openLeadCard}
        setOpenLeadCard={setOpenLeadCard}
        cardFieldsState={cardFieldsState}
        setCardFieldsState={setCardFieldsState}
        handleOpenUpdateMenu={handleOpenNoteUpdateMenu}
        isUpdating={isUpdating}
        isUpdatingIndex={isUpdatingIndex}
        isDeletingIndex={isDeletingIndex}
        isDeletingFlag={isDeletingFlag}
        setDeleteSelectedIdx={setDeleteSelectedIdx}
        openDeletePopover={openDeletePopover}
        handleOpenNoteAddMenu={handleOpenNoteMenu}
        setAllDrivers={setAllDrivers}
        allDrivers={allDrivers}
        user={user}
      />
      <AddNewLeadModal
        openCreateLead={openCreateLead}
        handleOpenLeadCard={handleOpenLeadCard}
        setOpenCreateLead={setOpenCreateLead}
        setAllDrivers={setAllDrivers}
        allDrivers={allDrivers}
        setNewestLead={setNewestLead}
        allRecruiters={allRecruiters}
      />
      <AutoNotification messages={newLeads} />
      <DeleteNotePopover
        anchorDeleteNoteEl={anchorDeleteNoteEl}
        closeDeletePopover={closeDeletePopover}
        handleDeleteNote={handleDeleteNote}
      />
    </>
  );
}
