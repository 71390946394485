import { useContext } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import IdentifyPage from './pages/IdentifyPage';
import ResetPassword from './pages/ResetPassword';
import TokenPasswordReset from './pages/TokenResetPassword';
import { UserContext } from './context/userContext';
import SettingsPage from './pages/SettingsPage';
import { ForceLogin } from './pages/ForceLogin';

// ----------------------------------------------------------------------

export default function Router() {
  const { isLoggedIn, user } = useContext(UserContext);
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" replace />,
      children: [
        { element: <Navigate to="/dashboard/leads" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'leads', element: <UserPage /> },
        { path: 'offers', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
        {
          path: 'settings',
          element: user && !user.recruiter_id ? <SettingsPage /> : <Navigate to="/dashboard/leads" replace />,
        },
      ],
    },
    {
      path: 'login',
      element: !isLoggedIn ? <LoginPage /> : <Navigate to="/dashboard/leads" replace />,
    },
    {
      path: 'identify',
      element: !isLoggedIn ? <IdentifyPage /> : <Navigate to="/dashboard/leads" replace />,
    },
    {
      path: 'reset-password',
      element: !isLoggedIn ? <ResetPassword /> : <Navigate to="/dashboard/leads" replace />,
    },
    {
      path: 'q/:tokenSlug',
      element: <ForceLogin />,
    },
    {
      path: 'reset-password/:key',
      element: !isLoggedIn ? <TokenPasswordReset /> : <Navigate to="/dashboard/leads" replace />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/leads" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
