import { useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { UserContext } from '../context/userContext';

export const ForceLogin = () => {
  const { setUser, setIsLoggedIn } = useContext(UserContext);
  const navigate = useNavigate();
  const { tokenSlug } = useParams();
  console.log(tokenSlug);

  useEffect(() => {
    const authorizeAndFetchCompany = async () => {
      try {
        const authResponse = await fetch(
          `https://app.truckersflow.com/wp-json/data-api/v1/authorization-check/${tokenSlug}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization:
                'Bearer DRNibve10j5QAItTgGMD9TQzieIGxyqKkrd9CCAfXtmvb9jKjUzOsQvOJeWnuFOFiVyEYgjHleJ5IxBii95jnBDrwo9whlM4R7sNUyUmYaKNwrDP1pqFzzUTJx2Gsi0o',
            },
          }
        );
        const companyIdData = await authResponse.json();

        if (!authResponse.ok) {
          throw new Error(companyIdData.message || 'Authorization failed');
        }

        const companyResponse = await fetch(
          `https://app.truckersflow.com/wp-json/data-api/v1/company/${companyIdData}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization:
                'Bearer DRNibve10j5QAItTgGMD9TQzieIGxyqKkrd9CCAfXtmvb9jKjUzOsQvOJeWnuFOFiVyEYgjHleJ5IxBii95jnBDrwo9whlM4R7sNUyUmYaKNwrDP1pqFzzUTJx2Gsi0o',
            },
          }
        );

        if (!companyResponse.ok) {
          const companyData = await companyResponse.json();
          throw new Error(companyData.message || 'Failed to fetch company data');
        }

        const companyData = await companyResponse.json();

        localStorage.setItem('user', JSON.stringify(companyData));
        setUser(companyData);
        setIsLoggedIn(true);
        const checkUser = localStorage.getItem('user');
        console.log(companyData, 'what is this data');
        console.log(checkUser, 'what is this data');
      } catch (error) {
        console.error('An error occurred:', error);
        setUser(null);
      } finally {
        navigate('/dashboard/leads');
      }
    };

    if (tokenSlug) {
      authorizeAndFetchCompany();
    }
  }, [tokenSlug]);

  return null;
};
