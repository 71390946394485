import { FormControl, MenuItem, Select } from '@mui/material';
import React from 'react';

const positionOptions = {
  'company-driver': 'Company Driver',
  'owner-operator': 'Owner-Operator',
  'lease-purchase': 'Lease Purchase',
  'truck-rental': ' Truck Rental',
  'box-trucks': 'Box Truck',
};

const PositionSelect = ({ onChange, selectedPosition, width = '100%', filterBy, generalFetchLeads }) => {
  const handlePositionChange = (event) => {
    const newSelectedPosition = event.target.value;
    onChange(newSelectedPosition);
    if (filterBy) {
      generalFetchLeads({ filteredPosition: newSelectedPosition });
    }
  };

  return (
    <FormControl variant="outlined" fullWidth sx={{ width: width, p: 0, display: 'block' }}>
      <Select
        labelId="demo-simple-select-label"
        id="recruiter-select"
        fullWidth
        displayEmpty
        placeholder="position"
        value={selectedPosition}
        onChange={handlePositionChange}
        renderValue={(selected) => {
          if (!selected || selected === 'all') {
            return <em>{filterBy ? 'Filter by position' : 'position'}</em>;
          }
          return positionOptions[selected];
        }}
      >
        {filterBy && (
          <MenuItem
            sx={{
              bgcolor: 'lightblue',
            }}
            value="all"
          >
            All Positions
          </MenuItem>
        )}
        {Object.entries(positionOptions).map(([key, value]) => (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PositionSelect;
