import React from 'react';
import {
  Button,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
  Box,
  Select,
  MenuItem,
  TextareaAutosize,
  Typography,
  useMediaQuery,
  IconButton,
  CircularProgress,
  FormControl,
} from '@mui/material';
import { Field } from 'formik';
import Iconify from '../iconify';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import HomeIcon from '@mui/icons-material/Home';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import CustomToolTip from './CustomToolTip';
import ColoredSelect from './ColoredSelect';
import RecruiterSelect from './RecruiterSelect';
import { fromUTCtoTimezone } from 'src/helpers/ConvertToTimezone';
import PositionSelect from './PositionSelect';

const LeadCardContent = ({
  currentDriver,
  allRecruiters,
  handleOpenUpdateMenu,
  isUpdating,
  isUpdatingIndex,
  isDeletingIndex,
  isDeletingFlag,
  setDeleteSelectedIdx,
  openDeletePopover,
  handleOpenNoteAddMenu,
  isUserAdmin,
}) => {
  // "1-5 Months",
  // "6-11 Months",
  // "12-18 Months",
  // "19-23 Months",
  // "2-4 Years",
  // "4-7 Years",
  // "7-11 Years",
  // "11-15 Years",
  // "15+ Years",
  const experienceOptions = ['0-5 months', '5-12 months', '1-2 years', '2-3 years', '3+ years'];
  const homeTimeOptions = ['weekly', 'every 2 weeks', 'monthly', 'no preference'];
  const canStartWorkingOptions = ['ASAP', '1 week', '2 weeks', '1 month', 'More than a month'];
  const trailerTypeOptions = ['Reefer', 'Dry Van', 'Flatbed', 'Step Deck', 'Conestoga'];
  const positionOptions = ['Company Driver', 'Owner-operator', 'Truck rental', 'Lease Purchase', 'Box Truck'];
  const drugTestStatus = ['Scheduled', 'Completed', 'Refused', 'Negative (passed)', 'Positive (failed)'];
  const failedTestSAP = [`I'm on SAP / I have recent DUI`, `I'm all good - no failed test and no DUI`];
  const MVRStatus = ['Yes, my record is spotless', 'I have some minor accidents', 'I have more than 3 accidents'];
  const clearingHouse = ['Clean', 'SAP'];

  const screenResponsive = useMediaQuery('(max-width: 1367px)');

  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={1}
      sx={{
        mt: '4px',
      }}
    >
      <Grid item xs={8}>
        <Grid container alignItems="stretch">
          <Grid item xs={6}>
            {isUserAdmin && allRecruiters.length > 1 && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  p: '10px 10px 10px 0',
                  maxWidth: screenResponsive ? '90%' : '70%',
                  justifyContent: 'space-between',
                }}
              >
                <label htmlFor="status-input" style={{ marginRight: 12, display: 'flex', alignItems: 'center' }}>
                  Recruiter:
                </label>
                <Field name="recruiter_id">
                  {({ field, form }) => (
                    <RecruiterSelect
                      allRecruiters={allRecruiters}
                      selectedRecruiter={field.value}
                      onChange={(changedRecruiter) => {
                        form.setFieldValue(field.name, changedRecruiter);
                      }}
                    />
                  )}
                </Field>
              </Box>
            )}
            <Typography lineHeight="42px" fontSize="20px" fontWeight="600">
              Personal information:
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: '10px 10px 10px 0',
                maxWidth: screenResponsive ? '90%' : '70%',
              }}
            >
              <CustomToolTip placement="top" offset={[0, 0]} toolTipTitle="Full Name">
                <PersonIcon
                  sx={{ color: '#028090', zIndex: 5, backgroundColor: 'inherit', mr: '4px' }}
                  fontSize="medium"
                  color="inherit"
                />
              </CustomToolTip>

              <Field
                as={TextField}
                id="meta_full_name"
                name="meta_full_name"
                variant="outlined"
                placeholder="Name"
                sx={{ flexGrow: 1 }}
                inputProps={{
                  sx: {
                    padding: '2px 10px',
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: '10px 10px 10px 0',
                maxWidth: screenResponsive ? '90%' : '70%',
              }}
            >
              <CustomToolTip placement="top" offset={[0, 0]} toolTipTitle="Phone number">
                <PhoneIphoneIcon
                  sx={{ color: '#028090', zIndex: 5, backgroundColor: 'inherit', mr: '4px' }}
                  fontSize="medium"
                  color="inherit"
                />
              </CustomToolTip>
              <Field
                as={TextField}
                id="meta_phone_number"
                name="meta_phone_number"
                variant="outlined"
                placeholder="Phone Number"
                sx={{ flexGrow: 1 }}
                inputProps={{
                  sx: {
                    padding: '2px 10px',
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: '10px 10px 10px 0',
                maxWidth: screenResponsive ? '90%' : '70%',
              }}
            >
              <CustomToolTip placement="top" offset={[0, 0]} toolTipTitle="Email">
                <EmailIcon
                  sx={{ color: '#028090', zIndex: 5, backgroundColor: 'inherit', mr: '4px' }}
                  fontSize="medium"
                  color="inherit"
                />
              </CustomToolTip>
              <Field
                as={TextField}
                id="meta_email"
                name="meta_email"
                variant="outlined"
                placeholder="Email"
                sx={{ flexGrow: 1 }}
                inputProps={{
                  sx: {
                    padding: '2px 10px',
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: '10px 10px 10px 0',
                maxWidth: screenResponsive ? '90%' : '70%',
              }}
            >
              <CustomToolTip placement="top" offset={[0, 0]} toolTipTitle="State">
                <HolidayVillageIcon
                  sx={{ color: '#028090', zIndex: 5, backgroundColor: 'inherit', mr: '4px' }}
                  fontSize="medium"
                  color="inherit"
                />
              </CustomToolTip>

              <Field
                as={TextField}
                id="meta_state"
                name="meta_state"
                variant="outlined"
                placeholder="State"
                sx={{ flexGrow: 1 }}
                inputProps={{
                  sx: {
                    padding: '2px 10px',
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                py: '5px',
                maxWidth: screenResponsive ? '90%' : '70%',
              }}
            >
              <Field name="meta_are_you_an_owner_op">
                {({ field }) => (
                  <FormControl
                    component="fieldset"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <label htmlFor="violation-input" style={{ marginRight: 12, display: 'flex', alignItems: 'center' }}>
                      Owner Operator:
                    </label>
                    <RadioGroup
                      {...field}
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="meta_are_you_an_owner_op"
                      value={field.value}
                      onChange={field.onChange}
                      row
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <FormControlLabel value="true" control={<Radio size="small" />} label="Yes" />
                      <FormControlLabel value="false" control={<Radio size="small" />} label="No" />
                    </RadioGroup>
                  </FormControl>
                )}
              </Field>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: '10px 10px 10px 0',
                maxWidth: screenResponsive ? '90%' : '70%',
              }}
            >
              <label htmlFor="meta_owner_op" style={{ marginRight: 12, textAlign: 'center', whiteSpace: 'nowrap' }}>
                Truck:
              </label>
              <Field
                as={TextField}
                id="meta_owner_op"
                name="meta_owner_op"
                variant="outlined"
                placeholder="Type"
                sx={{ flexGrow: 1 }}
                inputProps={{
                  sx: {
                    padding: '2px 10px',
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: '10px 10px 10px 0',
                maxWidth: screenResponsive ? '90%' : '70%',
              }}
            >
              <label htmlFor="meta_endorsments" style={{ marginRight: 12, textAlign: 'center', whiteSpace: 'nowrap' }}>
                Endorsements:
              </label>
              <Field
                as={TextField}
                id="meta_endorsments"
                name="meta_endorsments"
                variant="outlined"
                placeholder="Hazmat, TWIC"
                sx={{ flexGrow: 1 }}
                inputProps={{
                  sx: {
                    padding: '2px 10px',
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Typography lineHeight="42px" fontSize="20px" fontWeight="600">
              Driver Record:
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: '10px 10px 10px 0',
                maxWidth: screenResponsive ? '90%' : '70%',
                justifyContent: 'space-between',
              }}
            >
              <label htmlFor="status-input" style={{ marginRight: 12, display: 'flex', alignItems: 'center' }}>
                Status:
              </label>
              <Field name="status">
                {({ field, form }) => (
                  <ColoredSelect
                    initialValue={field.value || 'not contacted'}
                    onChange={(newStatus) => {
                      form.setFieldValue(field.name, newStatus);
                    }}
                  />
                )}
              </Field>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: '10px 10px 10px 0',
                maxWidth: screenResponsive ? '90%' : '70%',
              }}
            >
              <label htmlFor="meta_experience" style={{ marginRight: 12, display: 'flex', alignItems: 'center' }}>
                Experience:
              </label>
              <Field name="meta_experience">
                {({ field }) => (
                  <FormControl fullWidth>
                    <Select
                      {...field}
                      labelId="meta_experience"
                      id="meta_experience"
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected === '') {
                          return <span style={{ color: '#999' }}>select experience</span>;
                        }
                        return experienceOptions.find((option) => option === selected);
                      }}
                    >
                      {experienceOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Field>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                py: '5px',
                maxWidth: screenResponsive ? '90%' : '70%',
              }}
            >
              <Field name="meta_violation">
                {({ field }) => (
                  <FormControl
                    component="fieldset"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <label htmlFor="violation-input" style={{ marginRight: 12, display: 'flex', alignItems: 'center' }}>
                      Violation:
                    </label>
                    <RadioGroup
                      {...field}
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="meta_violation"
                      value={field.value}
                      onChange={field.onChange}
                      row
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <FormControlLabel value="true" control={<Radio size="small" />} label="Yes" />
                      <FormControlLabel value="false" control={<Radio size="small" />} label="No" />
                    </RadioGroup>
                  </FormControl>
                )}
              </Field>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                py: '5px',
                maxWidth: screenResponsive ? '90%' : '70%',
              }}
            >
              <Field name="meta_accident">
                {({ field }) => (
                  <FormControl
                    component="fieldset"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <label htmlFor="meta_accident" style={{ marginRight: 12, display: 'flex', alignItems: 'center' }}>
                      Accident:
                    </label>
                    <RadioGroup
                      {...field}
                      aria-labelledby="meta_accident"
                      name="meta_accident"
                      value={field.value}
                      onChange={field.onChange}
                      row
                    >
                      <FormControlLabel value="true" control={<Radio size="small" />} label="Yes" />
                      <FormControlLabel value="false" control={<Radio size="small" />} label="No" />
                    </RadioGroup>
                  </FormControl>
                )}
              </Field>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                py: '5px',
              }}
            >
              <Field name="meta_lp_experience">
                {({ field }) => (
                  <FormControl
                    component="fieldset"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <label
                      htmlFor="meta_lp_experience"
                      style={{ marginRight: 12, display: 'flex', alignItems: 'center' }}
                    >
                      LP Experience:
                    </label>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="meta_lp_experience"
                      value={field.value}
                      onChange={field.onChange}
                      row
                    >
                      <FormControlLabel value="true" control={<Radio size="small" />} label="Yes" />
                      <FormControlLabel value="false" control={<Radio size="small" />} label="No" />
                    </RadioGroup>
                  </FormControl>
                )}
              </Field>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: '10px 10px 10px 0',
                maxWidth: screenResponsive ? '90%' : '70%',
              }}
            >
              <label
                htmlFor="meta_clearing_house"
                style={{ marginRight: 12, textAlign: 'center', whiteSpace: 'nowrap' }}
              >
                Clearing House:
              </label>
              <Field name="meta_clearing_house">
                {({ field }) => (
                  <FormControl fullWidth>
                    <Select
                      {...field}
                      labelId="meta_clearing_house"
                      id="meta_clearing_house"
                      displayEmpty
                      fullWidth
                      placeholder="position"
                      renderValue={(selected) => {
                        if (selected === '') {
                          return <span style={{ color: '#999' }}>clearing status</span>;
                        }
                        return clearingHouse.find((option) => option === selected);
                      }}
                    >
                      {clearingHouse.map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </Field>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: '10px 10px 10px 0',
                maxWidth: screenResponsive ? '90%' : '70%',
              }}
            >
              <label htmlFor="meta_drug_test" style={{ marginRight: 12, textAlign: 'center', whiteSpace: 'nowrap' }}>
                Drug Test:
              </label>
              <Field name="meta_drug_test">
                {({ field }) => (
                  <FormControl fullWidth>
                    <Select
                      {...field}
                      labelId="meta_drug_test"
                      id="meta_drug_test"
                      displayEmpty
                      fullWidth
                      placeholder="test"
                      renderValue={(selected) => {
                        if (selected === '') {
                          return <span style={{ color: '#999' }}>drug test status</span>;
                        }
                        return drugTestStatus.find((option) => option === selected);
                      }}
                    >
                      {drugTestStatus.map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </Field>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: '10px 10px 10px 0',
                maxWidth: screenResponsive ? '90%' : '70%',
              }}
            >
              <label
                htmlFor="meta_recent_dui_failed_sap"
                style={{ marginRight: 12, textAlign: 'center', whiteSpace: 'nowrap' }}
              >
                SAP / DUI:
              </label>
              <Field name="meta_recent_dui_failed_sap">
                {({ field }) => (
                  <FormControl fullWidth>
                    <Select
                      {...field}
                      labelId="meta_recent_dui_failed_sap"
                      id="meta_recent_dui_failed_sap"
                      displayEmpty
                      fullWidth
                      placeholder="position"
                      renderValue={(selected) => {
                        if (selected === '') {
                          return <span style={{ color: '#999' }}>SAP</span>;
                        }
                        return failedTestSAP.find((option) => option === selected);
                      }}
                    >
                      {failedTestSAP.map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </Field>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: '10px 10px 10px 0',
                maxWidth: screenResponsive ? '90%' : '70%',
              }}
            >
              <label htmlFor="meta_clean_mvr" style={{ marginRight: 12, textAlign: 'center', whiteSpace: 'nowrap' }}>
                Clean MVR:
              </label>
              <Field name="meta_clean_mvr">
                {({ field }) => (
                  <FormControl fullWidth>
                    <Select
                      {...field}
                      labelId="meta_clean_mvr"
                      id="meta_clean_mvr"
                      displayEmpty
                      fullWidth
                      placeholder="position"
                      renderValue={(selected) => {
                        if (selected === '') {
                          return <span style={{ color: '#999' }}>MVR status</span>;
                        }
                        return MVRStatus.find((option) => option === selected);
                      }}
                    >
                      {MVRStatus.map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </Field>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Typography lineHeight="42px" fontSize="20px" fontWeight="600">
              Work Preferences:
            </Typography>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                py: '10px',
                maxWidth: screenResponsive ? '90%' : '70%',
              }}
            >
              <label htmlFor="driver_position" style={{ marginRight: 12, textAlign: 'center', whiteSpace: 'nowrap' }}>
                Interested in:
              </label>
              <Field name="driver_position">
                {({ field, form }) => (
                  <PositionSelect
                    selectedPosition={field.value}
                    onChange={(changedRecruiter) => {
                      form.setFieldValue(field.name, changedRecruiter);
                    }}
                  />
                )}
              </Field>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                py: '10px',
                maxWidth: screenResponsive ? '90%' : '70%',
              }}
            >
              <CustomToolTip placement="top" offset={[0, 0]} toolTipTitle="Truck Preference">
                <LocalShippingIcon
                  sx={{ color: '#028090', zIndex: 5, backgroundColor: 'inherit', mr: '6px' }}
                  fontSize="medium"
                  color="inherit"
                />
              </CustomToolTip>
              <Box>
                <Field
                  as={TextField}
                  id="meta_truck_preference_1"
                  name="meta_truck_preference_1"
                  placeholder="1st preferred truck"
                  variant="outlined"
                  fullWidth
                  sx={{ flexGrow: 1 }}
                  inputProps={{
                    sx: {
                      padding: '2px 10px',
                    },
                  }}
                />

                <Field
                  as={TextField}
                  id="meta_truck_preference_2"
                  name="meta_truck_preference_2"
                  placeholder="2nd preferred truck"
                  variant="outlined"
                  fullWidth
                  sx={{ flexGrow: 1, pt: '20px' }}
                  inputProps={{
                    sx: {
                      padding: '2px 10px',
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                py: '10px',
                maxWidth: screenResponsive ? '90%' : '70%',
              }}
            >
              <CustomToolTip placement="top" offset={[0, 0]} toolTipTitle="Trailer Type">
                <RvHookupIcon
                  sx={{ color: '#028090', zIndex: 5, backgroundColor: 'inherit', mr: '4px' }}
                  fontSize="medium"
                  color="inherit"
                />
              </CustomToolTip>

              <Field name="meta_trailer_type">
                {({ field }) => (
                  <FormControl fullWidth>
                    <Select
                      {...field}
                      id="meta_trailer_type"
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected === '') {
                          return <span style={{ color: '#999' }}>choose trailer type</span>;
                        }
                        return trailerTypeOptions.find((option) => option === selected);
                      }}
                    >
                      {trailerTypeOptions.map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </Field>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                py: '10px',
                maxWidth: screenResponsive ? '90%' : '70%',
              }}
            >
              <CustomToolTip placement="top" offset={[0, 0]} toolTipTitle="Home-Time">
                <HomeIcon
                  sx={{ color: '#028090', zIndex: 5, backgroundColor: 'inherit', mr: '4px' }}
                  fontSize="medium"
                  color="inherit"
                />
              </CustomToolTip>
              <Field name="meta_home_time">
                {({ field }) => (
                  <FormControl fullWidth>
                    <Select
                      {...field}
                      id="meta_home_time"
                      name="meta_home_time"
                      displayEmpty
                      fullWidth
                      renderValue={(selected) => {
                        if (selected === '') {
                          return <span style={{ color: '#999' }}>preference</span>;
                        }
                        return homeTimeOptions.find((option) => option === selected);
                      }}
                    >
                      {homeTimeOptions.map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </Field>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                py: '10px',
                maxWidth: screenResponsive ? '90%' : '70%',
              }}
            >
              <CustomToolTip placement="top" offset={[0, 0]} toolTipTitle="Start time">
                <AccessTimeIcon
                  sx={{ color: '#028090', zIndex: 5, backgroundColor: 'inherit', mr: '4px' }}
                  fontSize="medium"
                  color="inherit"
                />
              </CustomToolTip>
              <Field name="meta_start_time">
                {({ field }) => (
                  <FormControl fullWidth>
                    <Select
                      {...field}
                      id="meta_start_time"
                      name="meta_start_time"
                      fullWidth
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected === '') {
                          return <span style={{ color: '#999' }}> start time</span>;
                        }
                        return canStartWorkingOptions.find((option) => option === selected);
                      }}
                    >
                      {canStartWorkingOptions.map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </Field>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Typography lineHeight="42px" fontSize="20px" fontWeight="600">
              Previous Employment:
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                py: '10px',
                maxWidth: screenResponsive ? '90%' : '70%',
              }}
            >
              <label htmlFor="meta_company_name" style={{ marginRight: 12, textAlign: 'center', whiteSpace: 'nowrap' }}>
                Company Name:
              </label>
              <Field
                as={TextField}
                id="meta_company_name"
                name="meta_company_name"
                variant="outlined"
                sx={{ flexGrow: 1 }}
                inputProps={{
                  sx: {
                    padding: '2px 10px',
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                py: '5px',
                maxWidth: screenResponsive ? '90%' : '70%',
              }}
            >
              <Field name="meta_employment">
                {({ field }) => (
                  <FormControl
                    component="fieldset"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <CustomToolTip placement="top" offset={[0, 0]} toolTipTitle="Currently Employed">
                      <label
                        htmlFor="meta_employment"
                        style={{ marginRight: 12, display: 'flex', alignItems: 'center' }}
                      >
                        Employment:
                      </label>
                    </CustomToolTip>
                    <RadioGroup
                      {...field}
                      aria-labelledby="meta_employment"
                      name="meta_employment"
                      value={field.value}
                      onChange={field.onChange}
                      row
                    >
                      <FormControlLabel value="true" control={<Radio size="small" />} label="Yes" />
                      <FormControlLabel value="false" control={<Radio size="small" />} label="No" />
                    </RadioGroup>
                  </FormControl>
                )}
              </Field>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                py: '10px',
                maxWidth: screenResponsive ? '90%' : '70%',
              }}
            >
              <label htmlFor="meta_position" style={{ marginRight: 12, textAlign: 'center', whiteSpace: 'nowrap' }}>
                Current:
              </label>

              <Field name="meta_position">
                {({ field }) => (
                  <FormControl fullWidth>
                    <Select
                      {...field}
                      labelId="meta_position"
                      id="meta_position"
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected === '') {
                          return <span style={{ color: '#999' }}>position</span>;
                        }
                        return positionOptions.find((option) => option === selected);
                      }}
                    >
                      {positionOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Field>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                py: '10px',
                maxWidth: screenResponsive ? '90%' : '70%',
              }}
            >
              <label htmlFor="meta_why" style={{ marginRight: 12, textAlign: 'center', whiteSpace: 'nowrap' }}>
                Reason (Why):
              </label>
              <Field
                as={TextField}
                id="meta_why"
                name="meta_why"
                variant="outlined"
                sx={{ flexGrow: 1 }}
                inputProps={{
                  sx: {
                    padding: '2px 10px',
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                py: '10px',
                maxWidth: screenResponsive ? '90%' : '70%',
              }}
            >
              <label htmlFor="meta_miles" style={{ marginRight: 12, textAlign: 'center', whiteSpace: 'nowrap' }}>
                Miles:
              </label>

              <Field
                as={TextField}
                id="meta_miles"
                name="meta_miles"
                variant="outlined"
                sx={{ flexGrow: 1 }}
                inputProps={{
                  sx: {
                    padding: '2px 10px',
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                py: '10px',
                maxWidth: screenResponsive ? '90%' : '70%',
              }}
            >
              <label htmlFor="meta_pay" style={{ marginRight: 12, textAlign: 'center', whiteSpace: 'nowrap' }}>
                Pay:
              </label>
              <Field
                as={TextField}
                id="meta_pay"
                name="meta_pay"
                variant="outlined"
                sx={{ flexGrow: 1 }}
                inputProps={{
                  sx: {
                    padding: '2px 10px',
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Box>
          <Box display="flex" justifyContent="space-between">
            <Typography lineHeight="42px" fontSize="20px" fontWeight="600">
              Notes:
            </Typography>
            <Button
              onClick={(event) => {
                handleOpenNoteAddMenu(event, currentDriver.lead_email);
              }}
            >
              + Add Note
            </Button>
          </Box>
          {currentDriver.driverNotes && currentDriver.driverNotes.length !== 0 ? (
            currentDriver.driverNotes.map((note, idx) => (
              <Grid
                key={idx}
                container
                alignItems="center"
                sx={{
                  borderBottom: 'dashed rgba(0, 0, 0, 0.5) 1px',
                  py: '6px',
                  '&:last-child': {
                    borderBottom: 'none',
                  },
                }}
              >
                <Grid item xs={1} sx={{ alignSelf: 'start' }}>
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      wordWrap: 'break-word',
                      overflowWrap: 'break-word',
                      fontWeight: '600',
                      color: 'black',
                    }}
                  >
                    {idx + 1} .
                  </Typography>
                </Grid>
                <Grid item xs={9} display={'flex'} flexDirection={'column'}>
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      wordWrap: 'break-word',
                      overflowWrap: 'break-word',
                      textAlign: 'justify',
                      pl: '3px',
                    }}
                  >
                    {note.note_text}
                  </Typography>
                  <Typography
                    sx={{
                      color: 'rgba(99, 115, 129, 0.8)',
                      ml: 'auto',
                      fontSize: '12px',
                      fontStyle: 'italic',
                      textAlign: 'justify',
                      pl: '3px',
                    }}
                  >
                    {note.note_modified_date && fromUTCtoTimezone({ inputTime: note.note_modified_date })}
                  </Typography>
                </Grid>
                <Grid item xs={2} container justifyContent="flex-end" alignItems="center">
                  <IconButton
                    onClick={(event) => {
                      handleOpenUpdateMenu(event, note, idx);
                    }}
                    sx={{
                      p: 0,
                    }}
                  >
                    {isUpdating && isUpdatingIndex === idx ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <Iconify icon={'eva:edit-fill'} />
                    )}
                  </IconButton>
                  <IconButton
                    disabled={isDeletingFlag}
                    onClick={(event) => {
                      setDeleteSelectedIdx(idx);
                      openDeletePopover(event);
                    }}
                    sx={{
                      color: 'error.main',
                      p: 0,
                      ml: '4px',
                    }}
                  >
                    {isDeletingIndex === idx ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <Iconify icon={'eva:trash-2-outline'} />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            ))
          ) : (
            <Typography
              lineHeight="24px"
              fontSize="16px"
              fontWeight="500"
              textAlign="center"
              fontStyle="italic"
              marginTop="20px"
            >
              You don't have any notes for this lead yet
            </Typography>
          )}
        </Box>
      </Grid>

      <Grid item xs={12} display={'flex'} justify="center" alignItems="center">
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            pr: '10px',
            pb: '8px',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Field
            as={TextField}
            id="meta_note"
            name="meta_note"
            sx={{ width: '100%', flexGrow: 1 }}
            label="Summary..."
            multiline
            rows={1}
            variant="outlined"
            InputProps={{
              inputComponent: TextareaAutosize,
              inputProps: {
                minRows: 1,
                sx: { width: '100%' },
              },
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default LeadCardContent;
