export const TABLE_HEAD = [
  { id: 'lead_created_time', label: 'Date', alignRight: false },
  { id: 'lead_campaign', label: 'Campaign', alignRight: false },
  { id: 'lead_first_name', label: 'Name', alignRight: false },
  { id: 'lead_email', label: 'Email', alignRight: false },
  { id: 'lead_experience', label: 'Exp', alignRight: false },
  { id: 'lead_phone_number', label: 'Phone', alignRight: false },
  { id: 'lead_status', label: 'Status', alignRight: false },
  { id: 'note', label: 'Note', alignRight: true },
  { id: 'lead_owner_op', label: 'O/O', alignRight: false },
  { id: 'lead_truck_type', label: 'Model', alignRight: false },
  { id: 'state', label: 'State', alignRight: false },
  { id: 'last_status_update', label: 'Last Status Update', alignRight: false },
  { id: 'lp_experience', label: 'LP Experience', alignRight: false },
  { id: 'interested_in', label: 'Interested In', alignRight: false },
  { id: 'preferred_trailer_type', label: 'PTT', alignRight: false },
  { id: 'truck_preferences', label: 'Truck Preferences', alignRight: false },
  { id: 'violation', label: 'Violation', alignRight: false },
  { id: 'accident', label: 'Accident', alignRight: false },
  { id: 'drug_test', label: 'Drug Test', alignRight: false },
  { id: 'mvr', label: 'MVR', alignRight: false },
  { id: 'clearing_house', label: 'Clearing house', alignRight: false },
  { id: 'dui_sap', label: 'DUI / SAP', alignRight: false },
  { id: 'home_time', label: 'Home Time', alignRight: false },
  { id: 'start_time', label: 'Start Time', alignRight: false },
  { id: 'previous_company', label: 'Previous Company', alignRight: false },
  { id: 'previous_employment', label: 'Previous Employment', alignRight: false },
  { id: 'previous_position', label: 'Previous Position', alignRight: false },
  { id: 'why', label: 'Why', alignRight: false },
  { id: 'previous_miles', label: 'Previous Miles', alignRight: false },
  { id: 'previous_pay', label: 'Previous Pay', alignRight: false },
  { id: 'endorsements', label: 'Endorsements', alignRight: false },
  { id: 'summary', label: 'Summary', alignRight: false },
];

export const displayDashboardColumns = {
  Date: true,
  Name: true,
  Email: true,
  Exp: true,
  Phone: true,
  Campaign: false,
  Status: true,
  'O/O': true,
  Model: true,
  Note: true,
  State: false,
  'Last Status Update': false,
  'LP Experience': false,
  'Interested In': false,
  PTT: false,
  'Truck Preferences': false,
  Violation: false,
  Accident: false,
  'Drug Test': false,
  MVR: false,
  'Clearing house': false,
  'DUI / SAP': false,
  'Home Time': false,
  'Start Time': false,
  'Previous Company': false,
  'Previous Employment': false,
  'Previous Position': false,
  Why: false,
  'Previous Miles': false,
  'Previous Pay': false,
  Endorsements: false,
  Summary: false,
};
