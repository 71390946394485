import React from 'react';
import OTRLogo from '../../assets/OTRfavicon.ico';
import facebookLogo from '../../assets/facebookIcon.png';
import {
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  TableContainer,
  Box,
  CircularProgress,
  Avatar,
} from '@mui/material';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { DuplicateLeadIcon } from './DuplicateLeadIcon';
import { UserListHead } from '../../sections/@dashboard/user';
import ColoredSelect from './ColoredSelect';
import CopyButton from './CopyButton';
import CustomToolTip from './CustomToolTip';
import { fromUTCtoTimezone } from '../../helpers/ConvertToTimezone';
import { getYesNoFromString } from './helpers';

const DashboardTable = ({
  allDrivers,
  displayDataColumns,
  fetchingDataLoader,
  handleOpenLeadCard,
  handleStatusChange,
  handleOpenNoteMenu,
  isNotFound,
  currentSearchedStatus,
  companyLogo,
  dataColumnsBooleanState,
  filterName,
}) => {
  const renderDriverNameCell = (driverData) => {
    const driverFullName =
      driverData?.lead_meta_full_name || `${driverData.lead_first_name} ${driverData.lead_last_name}`;
    let avatarSrc;

    switch (driverData.lead_source) {
      case 'Recruiter':
        avatarSrc = companyLogo;
        break;
      case 'Facebook':
        avatarSrc = facebookLogo;
        break;
      case 'OpenOTR':
        avatarSrc = OTRLogo;
        break;
      default:
        avatarSrc = null;
    }
    return (
      <Stack direction="row" alignItems="center" spacing={2} sx={{ width: '100%' }}>
        {avatarSrc && (
          <Avatar
            sx={{
              height: '20px',
              width: '20px',
            }}
            src={avatarSrc}
            alt="photoURL"
          />
        )}
        <Typography
          variant="subtitle2"
          noWrap
          textTransform={'capitalize'}
          sx={{
            ml: '8px !important',
          }}
        >
          {driverFullName}
        </Typography>
        <CopyButton cellValue={driverFullName} />
      </Stack>
    );
  };

  return (
    <>
      {fetchingDataLoader ? (
        <>
          {allDrivers.length !== 0 ? (
            <TableContainer className="modern-scrollbar" sx={{ height: 'calc(100vh - 270px)', overflow: 'auto' }}>
              <Table stickyHeader>
                <UserListHead headLabel={displayDataColumns} rowCount={allDrivers.length} />
                <>
                  <TableBody>
                    {allDrivers.map((row, id) => {
                      const {
                        lead_email,
                        lead_id,
                        lead_experience,
                        lead_are_you_an_owner_op,
                        lead_owner_op = '---',
                        lead_phone_number,
                        lead_status,
                        lead_created_time,
                        driverNotes = [],
                        lead_meta_email,
                        lead_meta_phone_number,
                        lead_meta_experience,
                        lead_meta_owner_op = '---',
                        lead_meta_are_you_an_owner_op,
                        lead_meta_state,
                        lead_state,
                        lead_status_modified,
                        lead_meta_lp_experience,
                        lead_meta_interested_in,
                        lead_interested_in,
                        lead_meta_trailer_type,
                        lead_kind_of_trailer,
                        lead_meta_truck_preference_1,
                        lead_meta_truck_preference_2,
                        lead_meta_violation,
                        lead_meta_accident,
                        lead_meta_drug_test,
                        lead_drug_test,
                        lead_meta_clean_mvr,
                        lead_clean_mvr,
                        lead_meta_clearing_house,
                        lead_clearing_house,
                        lead_recent_dui_failed_sap,
                        lead_meta_recent_dui_failed_sap,
                        lead_meta_home_time,
                        lead_meta_start_time,
                        lead_meta_company_name,
                        lead_meta_employment,
                        lead_meta_position,
                        lead_meta_why,
                        lead_meta_miles,
                        lead_meta_endorsments,
                        lead_endorsements,
                        lead_meta_note,
                        lead_campaign = '---',
                        lead_meta_pay,
                        lead_duplicated_phone_number,
                        lead_duplicated_email,
                      } = row;

                      const getMetaValue = (metaValue, leadValue) => {
                        return metaValue !== undefined ? metaValue || '---' : leadValue || '---';
                      };

                      const getYesNoFromBool = (metaValue, fallbackValue) =>
                        metaValue === 'true' || fallbackValue === 'true'
                          ? 'Yes'
                          : metaValue || fallbackValue
                          ? 'No'
                          : '---';

                      const displayLeadEmail =
                        lead_meta_email?.trim().toLowerCase() || lead_email?.trim().toLowerCase() || '';
                      const displayLeadPhone = lead_meta_phone_number || lead_phone_number || '';
                      const displayLeadExperience = lead_meta_experience || lead_experience || '';
                      const displayLeadKindOfTruck = getMetaValue(lead_meta_owner_op, lead_owner_op);
                      const displayOwnerOpText = getYesNoFromString(
                        lead_meta_are_you_an_owner_op,
                        lead_are_you_an_owner_op
                      );

                      const firstNoteText =
                        driverNotes && driverNotes.length !== 0 && driverNotes[0]?.note_text
                          ? driverNotes[0]?.note_text
                          : '';
                      const notesNumber = driverNotes && driverNotes.length !== 0 && driverNotes.length;

                      const remainingFields = {
                        State: lead_meta_state || lead_state || '---',
                        'Last Status Update':
                          lead_status === 'not contacted'
                            ? fromUTCtoTimezone({ inputTime: lead_created_time })
                            : fromUTCtoTimezone({ inputTime: lead_status_modified }) || '---',
                        'LP Experience': getYesNoFromBool(lead_meta_lp_experience),
                        'Interested In': getMetaValue(lead_meta_interested_in, lead_interested_in),
                        PTT: getMetaValue(lead_meta_trailer_type, lead_kind_of_trailer),
                        'Truck Preferences':
                          ((lead_meta_truck_preference_1 || lead_meta_truck_preference_2) &&
                            `${lead_meta_truck_preference_1}${
                              lead_meta_truck_preference_2 && ' | '
                            }${lead_meta_truck_preference_2}`) ||
                          '---',
                        Violation: getYesNoFromBool(lead_meta_violation),
                        Accident: getYesNoFromBool(lead_meta_accident),
                        'Drug Test': getMetaValue(lead_meta_drug_test, lead_drug_test),
                        MVR: getMetaValue(lead_meta_clean_mvr, lead_clean_mvr),
                        'Clearing house': getMetaValue(lead_meta_clearing_house, lead_clearing_house),
                        'DUI / SAP': getMetaValue(lead_meta_recent_dui_failed_sap, lead_recent_dui_failed_sap),
                        'Home Time': lead_meta_home_time || '---',
                        'Start Time': lead_meta_start_time || '---',
                        'Previous Company': lead_meta_company_name || '---',
                        'Previous Employment': getYesNoFromBool(lead_meta_employment),
                        'Previous Position': lead_meta_position || '---',
                        Why: lead_meta_why || '---',
                        'Previous Miles': lead_meta_miles || '---',
                        'Previous Pay': lead_meta_pay || '---',
                        Endorsements: getMetaValue(lead_meta_endorsments, lead_endorsements),
                        Summary: lead_meta_note || '---',
                      };

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          sx={{
                            backgroundColor: lead_duplicated_phone_number === '1' ? 'lightyellow' : '',
                            '&:hover': {
                              backgroundColor:
                                lead_duplicated_phone_number === '1'
                                  ? 'rgba(255, 255, 224, 0.5) !important'
                                  : 'inherit',
                            },
                          }}
                        >
                          {dataColumnsBooleanState['Date'] && (
                            <TableCell
                              sx={{
                                height: '20px',
                                padding: '2px 16px 2px 8px',
                                minHeight: '20px',
                                cursor: 'pointer',
                              }}
                              scope="row"
                              size="small"
                              onClick={() => {
                                handleOpenLeadCard(lead_id);
                              }}
                            >
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap textTransform={'capitalize'}>
                                  {fromUTCtoTimezone({
                                    inputTime: lead_created_time,
                                    outputFormat: 'MM/DD/YYYY',
                                  })}
                                </Typography>
                              </Stack>
                            </TableCell>
                          )}
                          {dataColumnsBooleanState['Campaign'] && (
                            <TableCell
                              sx={{
                                height: '20px',
                                padding: '2px 32px 2px 16px',
                                minHeight: '20px',
                                cursor: 'pointer',
                                maxWidth: '150px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                position: 'relative',
                              }}
                              align="left"
                              onClick={() => {
                                handleOpenLeadCard(lead_id);
                              }}
                            >
                              {lead_campaign}
                            </TableCell>
                          )}
                          {dataColumnsBooleanState['Name'] && (
                            <TableCell
                              className="tableCell"
                              sx={{
                                height: '20px',
                                padding: '2px 32px 2px 16px',
                                minHeight: '20px',
                                cursor: 'pointer',
                                maxWidth: '250px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                position: 'relative',
                              }}
                              scope="row"
                              size="small"
                              onClick={() => {
                                handleOpenLeadCard(lead_id);
                              }}
                            >
                              {renderDriverNameCell(row)}
                            </TableCell>
                          )}
                          {dataColumnsBooleanState['Email'] && (
                            <TableCell
                              className="tableCell"
                              sx={{
                                height: '20px',
                                padding: '2px 16px 2px 8px',
                                minHeight: '20px',
                                cursor: 'pointer',
                                maxWidth: '200px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                position: 'relative',
                              }}
                              align="left"
                              onClick={() => {
                                handleOpenLeadCard(lead_id);
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ width: '100%', justifyContent: 'flex-start' }}
                              >
                                {lead_duplicated_email && <DuplicateLeadIcon />}
                                <Typography noWrap fontSize="0.875rem">
                                  {displayLeadEmail}
                                </Typography>
                                {displayLeadEmail && <CopyButton cellValue={displayLeadEmail} />}
                              </Stack>
                            </TableCell>
                          )}
                          {dataColumnsBooleanState['Exp'] && (
                            <TableCell
                              sx={{
                                height: '20px',
                                padding: '2px 16px 2px 8px',
                                minHeight: '20px',
                                cursor: 'pointer',
                                minWidth: '90px',
                                maxWidth: '95px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                              align="left"
                              onClick={() => {
                                handleOpenLeadCard(lead_id);
                              }}
                            >
                              {displayLeadExperience}
                            </TableCell>
                          )}
                          {dataColumnsBooleanState['Phone'] && (
                            <TableCell
                              className="tableCell"
                              sx={{
                                height: '20px',
                                padding: '2px 16px 2px 8px',
                                minHeight: '20px',
                                cursor: 'pointer',
                                position: 'relative',
                                width: '160px',
                                minWidth: '140px',
                                maxWidth: '161px',
                              }}
                              align="left"
                            >
                              {displayLeadPhone && (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  sx={{ minWidth: '145px', maxWidth: '150px !important' }}
                                >
                                  {lead_duplicated_phone_number && <DuplicateLeadIcon />}
                                  <Typography variant="subtitle2" noWrap>
                                    <a
                                      className="phoneLink"
                                      href={`tel:+${displayLeadPhone.replace(/[^0-9+]/g, '')}`}
                                      target="_blank"
                                    >
                                      {displayLeadPhone}
                                    </a>
                                  </Typography>

                                  <CopyButton cellValue={displayLeadPhone} />
                                </Stack>
                              )}
                            </TableCell>
                          )}
                          {dataColumnsBooleanState['Status'] && (
                            <TableCell
                              sx={{
                                height: '20px',
                                padding: '2px 16px 2px 8px',
                                minHeight: '20px',
                                maxWidth: '200px',
                                minWidth: '170px',
                              }}
                              align="left"
                            >
                              <ColoredSelect
                                initialValue={lead_status || 'not contacted'}
                                onChange={(newStatus) => handleStatusChange(lead_id, newStatus)}
                              />
                            </TableCell>
                          )}
                          {dataColumnsBooleanState['Note'] && (
                            <TableCell
                              sx={{
                                height: '20px',
                                padding: '2px 16px 2px 8px',
                                minHeight: '20px',
                                maxWidth: '210px',
                                minWidth: '80px',
                                position: 'relative',
                              }}
                              align="right"
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                <CustomToolTip toolTipTitle={firstNoteText}>
                                  <Typography
                                    sx={{
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      marginRight: '8px',
                                      fontSize: '14px',
                                    }}
                                  >
                                    {firstNoteText}
                                  </Typography>
                                </CustomToolTip>
                                <IconButton
                                  size="small"
                                  color="inherit"
                                  sx={{
                                    zIndex: 1,
                                    position: 'relative',
                                  }}
                                  onClick={(event) => {
                                    handleOpenNoteMenu(event, lead_email);
                                  }}
                                >
                                  <NoteAddIcon
                                    sx={{
                                      color: '#028090',
                                      zIndex: 5,
                                      backgroundColor: 'inherit',
                                    }}
                                    size={30}
                                    color="inherit"
                                  />
                                </IconButton>
                                {notesNumber && (
                                  <Typography
                                    sx={{
                                      position: 'absolute',
                                      right: '4px',
                                      top: '50%',
                                      transform: 'translateY(-50%)',
                                      borderRadius: '20%',
                                      width: '18px',
                                      height: '20px',
                                      overflow: 'hidden',
                                      backgroundColor: 'rgba(32, 101, 209, 0.6)',
                                      color: 'white',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      fontWeight: 'bold',
                                      fontSize: '12px',
                                    }}
                                  >
                                    <span>{notesNumber}</span>
                                  </Typography>
                                )}
                              </Box>
                            </TableCell>
                          )}
                          {dataColumnsBooleanState['O/O'] && (
                            <TableCell
                              sx={{
                                height: '20px',
                                padding: '2px 16px 2px 8px',
                                minHeight: '20px',
                                minWidth: '57px',
                                maxWidth: '58px',
                                cursor: 'pointer',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                              align="left"
                              onClick={() => {
                                handleOpenLeadCard(lead_id);
                              }}
                            >
                              {displayOwnerOpText}
                            </TableCell>
                          )}
                          {dataColumnsBooleanState['Model'] && (
                            <TableCell
                              sx={{
                                height: '20px',
                                padding: '2px 16px 2px 8px',
                                minHeight: '20px',
                                minWidth: '115px',
                                maxWidth: '120px',
                                cursor: 'pointer',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                              align="left"
                              onClick={() => {
                                handleOpenLeadCard(lead_id);
                              }}
                            >
                              {displayLeadKindOfTruck}
                            </TableCell>
                          )}

                          {Object.entries(remainingFields).map(
                            ([key, value]) =>
                              dataColumnsBooleanState[key] && (
                                <TableCell
                                  key={key}
                                  className="tableCell"
                                  sx={{
                                    height: '20px',
                                    padding: '2px 32px 2px 16px',
                                    minHeight: '20px',
                                    cursor: 'pointer',
                                    minWidth: '160px',
                                    maxWidth: '180px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    position: 'relative',
                                  }}
                                  scope="row"
                                  size="small"
                                  onClick={() => handleOpenLeadCard(lead_id)}
                                >
                                  {value}
                                </TableCell>
                              )
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </>
              </Table>
            </TableContainer>
          ) : (
            <>
              <Box
                sx={{
                  height: 'calc(100vh - 218px)',
                  display: 'flex',
                  flexDirection: 'column',
                  pt: '60px',
                  alignItems: 'center',
                  overflow: 'auto',
                }}
              >
                {isNotFound ? (
                  <>
                    <Typography variant="h4" paragraph>
                      Not found
                    </Typography>
                    <Typography variant="body1">
                      No results found for{' '}
                      <Typography
                        component="span"
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        "{filterName}"
                      </Typography>{' '}
                      in the
                      <Typography
                        component="span"
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        {' '}
                        {currentSearchedStatus}
                      </Typography>{' '}
                      tab.
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="h4" paragraph>
                      Nothing here
                    </Typography>

                    <Typography variant="body1">
                      No results found in the{' '}
                      <Typography
                        component="span"
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        {currentSearchedStatus}
                      </Typography>{' '}
                      tab. Currently you don't have any leads with this status.
                    </Typography>
                  </>
                )}
              </Box>
            </>
          )}
        </>
      ) : (
        <Box
          sx={{
            height: 'calc(100vh - 218px)',
            display: 'flex',
            flexDirection: 'column',
            pt: '60px',
            alignItems: 'center',
            overflow: 'auto',
          }}
        >
          <CircularProgress sx={{ color: '#028090' }} size={50} color="inherit" />
        </Box>
      )}
    </>
  );
};

export default DashboardTable;
